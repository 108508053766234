import React, { useState } from 'react';

function AdminDashboard() {
  const [shields, setShields] = useState([
    { user: 'User 1', timeLeft: 10 },
    { user: 'User 2', timeLeft: 2 },
    { user: 'User 3', timeLeft: 0 },
  ]);

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <ul>
        {shields.map((shield, index) => (
          <li key={index} style={{ color: shield.timeLeft > 3 ? 'green' : shield.timeLeft > 0 ? 'yellow' : 'red' }}>
            {shield.user}: {shield.timeLeft > 0 ? `${shield.timeLeft} hours remaining` : 'Shield expired'}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminDashboard;
