import React, { useState } from 'react';

const ShieldEntry = () => {
  const [shieldData, setShieldData] = useState('');
  const [message, setMessage] = useState('');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/shield-entry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ shieldData }),
      });

      const data = await response.json();

      if (data.success) {
        setMessage('Shield data submitted successfully!');
        setShieldData(''); // Clear the input field after submission
      } else {
        setMessage('Failed to submit shield data');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div>
      <h1>Enter Shield Data</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="shieldData">Shield Data:</label>
          <input
            type="text"
            id="shieldData"
            value={shieldData}
            onChange={(e) => setShieldData(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ShieldEntry;
