import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LicensePage from './components/LicensePage';
import AdminDashboard from './components/AdminDashboard';
import ShieldEntry from './components/ShieldEntry';
import Navbar from './components/Navbar'; // Assuming you want a Navbar later on

function App() {
  return (
    <Router>
      <div>
        {/* Optional Navbar if needed */}
        <Navbar />

        {/* Define all your routes here */}
        <Routes>
          <Route path="/" element={<LicensePage />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/shield-entry" element={<ShieldEntry />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
