import React, { useState } from 'react';
import './LicensePage.css';

function LicensePage() {
  const [licenseKey, setLicenseKey] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Test key from the environment variable
    const testLicenseKey = process.env.REACT_APP_TEST_LICENSE_KEY || 'my-test-key';  // Default value if not found

    // Validate the license key and redirect accordingly
    switch (licenseKey) {
      case 'your_admin_key':
        window.location.href = '/admin';
        break;
      case 'your_user_key':
        window.location.href = '/shield-entry';
        break;
      case testLicenseKey:
        alert('Test License Key is valid! You will be directed to shield-entry.');
        window.location.href = '/shield-entry'; // Direct to shield-entry for test key
        break;
      default:
        alert('Invalid License Key');
        break;
    }
  };

  return (
    <div className="license-container">
      <h1>Enter License Key</h1>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          value={licenseKey} 
          onChange={(e) => setLicenseKey(e.target.value)} 
          placeholder="Enter License Key" 
          required 
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default LicensePage;
